@font-face {
  font-family: "Avenir-Medium";
  src: local("Avenir-Medium"), url(./Avenir-Font/AvenirLTStd-Medium.otf);
}
@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(./Avenir-Font/Avenir.otf);
}
@font-face {
  font-family: "Avenir-Book";
  src: local("Avenir-Book"), url(./Avenir-Font/AvenirLTStd-Book.otf);
}
@font-face {
  font-family: "Avenir-Black";
  src: local("Avenir-Black"), url(./Avenir-Font/AvenirLTStd-Black.otf);
}
