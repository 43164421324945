html,
body {
  height: 100%;
}
a {
  font-family: 'Avenir-Medium';
  font-size: 14px;
}
.react-daterange-picker__wrapper {
  border-color: rgba(155, 155, 155, 0.44) !important;
  border-radius: 23px !important;
}
.react-daterange-picker__inputGroup__input {
  color: #5c6979 !important;
}
.react-daterange-picker__inputGroup {
  margin-left: 5px;
}
.ic_container_header {
  display: flex;
  padding: 40px;
}
.ic_container_header > div:first-child {
  margin-right: auto;
  display: flex;
  flex: 1;
  justify-content: center;
}
.ic_container_header > div:last-child {
  margin-left: auto;
}
.dont-break-out > :not(.dont-break-out) {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: pre-wrap;
}
.cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(122, 115, 115, 0.7);
  z-index: 9999;
}
a:not([href]) {
  text-decoration: underline;
  cursor: pointer;
  color: #0000EE;
}
